exports.components = {
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-hotels-jsx": () => import("./../../../src/pages/hotels.jsx" /* webpackChunkName: "component---src-pages-hotels-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kooperationen-jsx": () => import("./../../../src/pages/kooperationen.jsx" /* webpackChunkName: "component---src-pages-kooperationen-jsx" */),
  "component---src-pages-kreuzfahrten-jsx": () => import("./../../../src/pages/kreuzfahrten.jsx" /* webpackChunkName: "component---src-pages-kreuzfahrten-jsx" */),
  "component---src-pages-kreuzfahrten-lektoren-jsx": () => import("./../../../src/pages/kreuzfahrten/lektoren.jsx" /* webpackChunkName: "component---src-pages-kreuzfahrten-lektoren-jsx" */),
  "component---src-pages-reiseziele-jsx": () => import("./../../../src/pages/reiseziele.jsx" /* webpackChunkName: "component---src-pages-reiseziele-jsx" */),
  "component---src-templates-city-hotels-page-jsx": () => import("./../../../src/templates/city-hotels-page.jsx" /* webpackChunkName: "component---src-templates-city-hotels-page-jsx" */),
  "component---src-templates-city-page-jsx": () => import("./../../../src/templates/city-page.jsx" /* webpackChunkName: "component---src-templates-city-page-jsx" */),
  "component---src-templates-city-sights-page-jsx": () => import("./../../../src/templates/city-sights-page.jsx" /* webpackChunkName: "component---src-templates-city-sights-page-jsx" */),
  "component---src-templates-country-hotels-page-jsx": () => import("./../../../src/templates/country-hotels-page.jsx" /* webpackChunkName: "component---src-templates-country-hotels-page-jsx" */),
  "component---src-templates-country-page-jsx": () => import("./../../../src/templates/country-page.jsx" /* webpackChunkName: "component---src-templates-country-page-jsx" */),
  "component---src-templates-country-sights-page-jsx": () => import("./../../../src/templates/country-sights-page.jsx" /* webpackChunkName: "component---src-templates-country-sights-page-jsx" */),
  "component---src-templates-cruise-ship-other-page-jsx": () => import("./../../../src/templates/cruise-ship-other-page.jsx" /* webpackChunkName: "component---src-templates-cruise-ship-other-page-jsx" */),
  "component---src-templates-cruise-ship-route-page-jsx": () => import("./../../../src/templates/cruise-ship-route-page.jsx" /* webpackChunkName: "component---src-templates-cruise-ship-route-page-jsx" */),
  "component---src-templates-cruise-ship-ship-page-jsx": () => import("./../../../src/templates/cruise-ship-ship-page.jsx" /* webpackChunkName: "component---src-templates-cruise-ship-ship-page-jsx" */),
  "component---src-templates-cruise-ship-tours-page-jsx": () => import("./../../../src/templates/cruise-ship-tours-page.jsx" /* webpackChunkName: "component---src-templates-cruise-ship-tours-page-jsx" */),
  "component---src-templates-cruise-ship-vendor-other-page-jsx": () => import("./../../../src/templates/cruise-ship-vendor-other-page.jsx" /* webpackChunkName: "component---src-templates-cruise-ship-vendor-other-page-jsx" */),
  "component---src-templates-cruise-ship-vendor-page-jsx": () => import("./../../../src/templates/cruise-ship-vendor-page.jsx" /* webpackChunkName: "component---src-templates-cruise-ship-vendor-page-jsx" */),
  "component---src-templates-hotel-page-jsx": () => import("./../../../src/templates/hotel-page.jsx" /* webpackChunkName: "component---src-templates-hotel-page-jsx" */),
  "component---src-templates-lector-page-jsx": () => import("./../../../src/templates/lector-page.jsx" /* webpackChunkName: "component---src-templates-lector-page-jsx" */),
  "component---src-templates-sight-page-jsx": () => import("./../../../src/templates/sight-page.jsx" /* webpackChunkName: "component---src-templates-sight-page-jsx" */),
  "component---src-templates-tour-page-jsx": () => import("./../../../src/templates/tour-page.jsx" /* webpackChunkName: "component---src-templates-tour-page-jsx" */)
}

